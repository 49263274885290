<template>
  <div>
    <v-row class="match-height">
      <v-col
        cols="12"
        md="4"
      >
        <v-card>
          <v-card-text class="greeting-title">
            <v-row>
              <v-col
                cols="12"
                md="8"
              >
                <div>
                  <p class="text-no-wrap text-xl">
                    Selamat Datang di
                  </p>
                  <p class="text--primary font-weight-bold text-xl mb-0">
                    Dashboard Superadmin
                    <span>🎉</span>
                  </p>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <div>
                  <!-- <v-img
                    width="80"
                    src="@/assets/images/misc/tree-4.png"
                    class="gamification-tree-4"
                  ></v-img> -->
                  <v-img
                    width="110"
                    src="@/assets/images/3d-characters/pose-2.png"
                    class="gamification-john-pose-2"
                  ></v-img>
                  <!-- <v-img
                    width="80"
                    src="@/assets/images/misc/tree.png"
                    class="gamification-tree"
                  ></v-img> -->
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- <v-card-title class="greeting-title d-flex flex-nowrap text-2xl">
          </v-card-title> -->
        </v-card>
      </v-col>
      <!-- <v-col
        cols="12"
        md="8"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Library</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col
                v-for="(library,index) in libraries"
                :key="index"
                md="3"
              >
                <v-row>
                  <v-col md="5">
                    <v-card :color="library.color">
                      <v-container>
                        <v-img
                          :src="library.icon"
                        ></v-img>
                      </v-container>
                    </v-card>
                  </v-col>
                  <v-col md="7">
                    <span>{{ library.title }}</span>
                    <p class="font-weight-bold text--black mb-0">
                      {{ library.total }}
                      <span
                        v-if="library.ratio > 0"
                        class="font-weight-light text-sm success--text mb-0 "
                      >
                        {{ "+"+library.ratio }}
                      </span>

                      <span
                        v-else
                        class="font-weight-light text-sm error--text mb-0 "
                      >
                        {{ library.ratio }}
                      </span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col> -->
      <v-col
        v-for="menu in menus"
        :key="menu.title"
        md="2"
        cols="12"
      >
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <v-avatar :color="menu.color">
              <v-container class="d-flex align-center">
                <v-img
                  :src="menu.icon"
                ></v-img>
              </v-container>
            </v-avatar>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <p class="text-sm font-weight-bold mb-2">
              {{ menu.title }}
            </p>
            <p class="text-xl font-weight-black mb-2">
              {{ menu.total }}
              <!-- <span
                v-if="menu.ratio > 0"
                class="font-weight-light text-sm success--text mb-0 "
              >
                {{ "+"+menu.ratio }}
              </span>

              <span
                v-else
                class="font-weight-light text-sm error--text mb-0 "
              >
                {{ menu.ratio }}
              </span> -->
            </p>
            <p class="text-subtitle-2 mb-0">
              Total {{ menu.title }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- <v-col
        v-for="item in items"
        :key="item.title"
        cols="12"
        sm="12"
        md="4"
        lg="4"
      >
        <v-card
          class="px-4 rounded-lg"
          :color="item.background"
        >
          <v-card-text class="d-flex justify-space-between align-center">
            <div class="card-info">
              <h1 class="white--text font-weight-bold">
                {{ item.total }}
              </h1>
              <h2 class="white--text font-weight-light">
                {{ item.title }}
              </h2>
            </div>
            <div class="card-img">
              <img
                :src="item.src"
                :width="item.imgWidth"
                :height="item.imgHeight"
                alt=""
              >
            </div>
            <img
              :src="item.shapes"
              alt=""
              class=""
              style="position: absolute;bottom: 0;left: 0;right: 0;width: 100%;height: 80px;z-index: 0;"
            >
            <img
              src="../../../assets/images/shapes/3.png"
              alt=""
              class=""
              style="position: absolute;bottom: 0;left: 0;right: 0;width: 100%;height: 80px;z-index: 0;"
            >
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    <v-row class="match-height">
      <v-col md="5">
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Sekolah Baru</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-simple-table class="overflow-hidden">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-left"
                  >
                    Nama Sekolah
                  </th>
                  <th class="text-left">
                    Jenjang
                  </th>
                  <th class="text-left">
                    Tanggal buat
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="school in schools"
                  :key="school.name"
                >
                  <td class="py-2">
                    <v-avatar
                      size="40"
                      :class="`v-avatar-light-bg ${randomTextColor()}`"
                      :color="randomBgColor()"
                    >
                      {{ school.level }}
                    </v-avatar>
                  </td>
                  <td>
                    <span class="font-weight-light text-truncate">{{ school.name }}</span>
                  </td>
                  <td>{{ school.level }}</td>
                  <td><span class="text-truncate">{{ dateFormat(school.created_at) }}</span></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- <v-card-actions class="justify-center">
            <router-link
              to=""
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col md="7">
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Sekolah Paling Aktif</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    colspan="2"
                    class="text-left"
                  >
                    Nama Sekolah
                  </th>
                  <th class="text-left">
                    Jenjang
                  </th>
                  <th class="text-left">
                    Kabupaten/Kota
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="activeSchool in activeSchools"
                  :key="activeSchool.name"
                >
                  <td class="py-2">
                    <v-avatar
                      size="40"
                      :class="`v-avatar-light-bg ${randomTextColor()}`"
                      :color="randomBgColor()"
                    >
                      {{ activeSchool.level }}
                    </v-avatar>
                  </td>
                  <td>
                    <span class="font-weight-light text-truncate">{{ activeSchool.name }}</span>
                  </td>
                  <td>{{ activeSchool.level }}</td>
                  <td>{{ activeSchool.regency }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <!-- <v-card-actions class="justify-center">
            <router-link
              :to="{name: 'most-active-school'}"
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions> -->
        </v-card>
      </v-col>
      <v-col md="5">
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Guru Paling Aktif</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                  >
                    Nama
                  </th>
                  <!-- <th class="text-left">
                    Aktifitas
                  </th> -->
                  <!-- <th class="text-left">
                    Bank Soal
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(activeTeacher,index) in activeTeachers"
                  :key="index"
                >
                  <td class="py-2 d-flex align-center">
                    <v-avatar>
                      <v-img
                        v-if="activeTeacher.photo"
                        :src="activeTeacher"
                        width="40"
                      ></v-img>
                      <v-img
                        else
                        src="@/assets/images/avatars/ari.png"
                        width="40"
                      ></v-img>
                    </v-avatar>
                    <span
                      class="d-inline-block font-weight-light ms-4 text-truncate"
                      style="max-width:150px"
                    >{{ activeTeacher.name }}</span>
                  </td>
                  <!-- <td>{{ activeTeacher.activity }}</td> -->
                  <!-- <td>{{ activeTeacher.question_bank_count }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="justify-center">
            <router-link
              :to="{name:'most-active-teacher'}"
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="7">
        <v-card :loading="isLoadingCard">
          <v-card-title>
            <h4>Siswa Paling Aktif</h4>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              class="me-n3 mt-n2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    class="text-left"
                  >
                    Nama
                  </th>
                  <th class="text-left">
                    Aktifitas
                  </th>
                  <!-- <th class="text-left">
                    Buku Dipinjam
                  </th>
                  <th class="text-left">
                    CBT
                  </th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(activeStudent,index) in activeStudents"
                  :key="index"
                >
                  <td class="py-2 d-flex align-center">
                    <v-avatar>
                      <v-img
                        v-if="activeStudent.photo"
                        :src="activeStudent"
                        width="40"
                      ></v-img>
                      <v-img
                        else
                        src="@/assets/images/avatars/ari.png"
                        width="40"
                      ></v-img>
                    </v-avatar>
                    <span
                      class="font-weight-light ms-4 text-truncate"
                      style="max-width: 150px"
                    >{{ activeStudent.name }}</span>
                  </td>
                  <td>{{ activeStudent.activity }}</td>
                  <!-- <td>{{ activeStudent.book_borrowed }}</td>
                  <td>{{ activeStudent.cbt }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card-actions class="justify-center">
            <router-link
              :to="{name:'most-active-student'}"
              class="text-decoration-none"
            >
              Lihat Semua
            </router-link>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card :loading="isLoadingCard">
          <v-card-title class="d-flex align-center">
            <h4>Aktifitas Penggunaan Aplikasi</h4>
            <v-spacer></v-spacer>
            <!-- <v-combobox
              outlined
              dense
              label="Tahun"
              :items="yearActivities"
              :value="2023"
              class="comboboxHidden"
              @change="handleSelectChange($event)"
            ></v-combobox> -->
            <v-btn
              icon
              small
              class="me-n3 mt-n2 ms-2"
            >
              <v-icon>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <vue-apex-charts
              id="total-profit-chart"
              ref="chart"
              height="320"
              :options="chartOptions"
              :series="chartData"
            ></vue-apex-charts>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row>
    </v-row> -->
  </div>
</template>

<script>
import iconBag from '@/assets/icons/white/bag.svg'
import iconBook from '@/assets/icons/white/book.svg'
import iconChart from '@/assets/icons/white/chart.svg'
import iconSchool from '@/assets/icons/white/courthouse.svg'
import iconDocumentText from '@/assets/icons/white/document-text.svg'
import iconKeyboard from '@/assets/icons/white/keyboard.svg'
import iconMessageText from '@/assets/icons/white/message-text.svg'
import iconMessages from '@/assets/icons/white/messages-2.svg'
import iconMonitorMobile from '@/assets/icons/white/monitor-mobile.svg'
import iconMonitor from '@/assets/icons/white/monitor.svg'
import iconMusic from '@/assets/icons/white/music.svg'
import iconNote from '@/assets/icons/white/note-2.svg'
import iconPlay from '@/assets/icons/white/play.svg'
import iconReceipt from '@/assets/icons/white/receipt-2.svg'
import iconSimcard from '@/assets/icons/white/simcard.svg'
import iconTaskSquare from '@/assets/icons/white/task-square.svg'
import iconUserOctagon from '@/assets/icons/white/user-octagon.svg'
import iconUser from '@/assets/icons/white/user.svg'
import dateTimeFormat from '@/utils/date/dateTimeFormat'
import mapView from '@amcharts/amcharts4-geodata/indonesiaHigh'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4maps from '@amcharts/amcharts4/maps'
import { getVuetify } from '@core/utils'
import { kFormatter } from '@core/utils/filter'
import { mdiChevronDown, mdiDotsVertical } from '@mdi/js'
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'DashboardSuperadmin',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      vuetify: getVuetify(),
      icons: {
        mdiDotsVertical,
        mdiChevronDown,
        iconBook,
        iconPlay,
        iconMusic,
        iconSchool,
        iconUser,
        iconChart,
        iconBag,
        iconUserOctagon,
        iconMonitor,
        iconMonitorMobile,
        iconNote,
        iconReceipt,
        iconMessageText,
        iconMessages,
        iconKeyboard,
        iconTaskSquare,
        iconDocumentText,
      },
      dashboard: {},
      isLoadingCard: true,
      menus: [
        {
          icon: iconSchool,
          title: 'Sekolah',
          total: 0,
          ratio: 0,
          color: '#EC008C',
          keyName: 'count_school',
          keyRatioName: 'ratio_school',
        },
        {
          icon: iconUser,
          title: 'Siswa',
          total: 0,
          ratio: 0,
          color: '#7D2B8B',
          keyName: 'count_student',
          keyRatioName: 'ratio_student',
        },
        {
          icon: iconUser,
          title: 'Guru',
          total: 0,
          ratio: 0,
          color: '#56CA00',
          keyName: 'count_teacher',
          keyRatioName: 'ratio_teacher',
        },
        {
          icon: iconUser,
          title: 'Pegawai',
          total: 0,
          ratio: 0,
          color: '#FF4C51',
          keyName: 'count_employee',
          keyRatioName: 'ratio_employee',
        },
        {
          icon: iconUserOctagon,
          title: 'Kadis',
          total: 0,
          ratio: 0,
          color: '#FFB400',
          keyName: 'count_kadis',
          keyRatioName: 'ratio_kadis',
        },
        {
          icon: iconUser,
          title: 'Kacabdis',
          total: 0,
          ratio: 0,
          color: '#16B1FF',
          keyName: 'count_kacabdis',
          keyRatioName: 'ratio_kacabdis',
        },
        {
          icon: iconMessageText,
          title: 'Berita',
          total: 0,
          ratio: 0,
          color: '#56CA00',
          keyName: 'count_news',
          keyRatioName: 'ratio_news',
        },
      ],
      topicAndForum: [
        {
          icon: iconMessages,
          title: 'Forum',
          total: 0,
          ratio: 0,
          color: '#853893',
          keyName: 'count_forum',
          keyRatioName: 'ratio_forum',
        },
        {
          icon: iconMessageText,
          title: 'Topik',
          total: 0,
          ratio: 0,
          color: '#FFB400',
          keyName: 'count_topic',
          keyRatioName: 'ratio_topic',
        },
      ],
      favoriteBooks: [],
      bookPurchases: [],
      yearActivities: [],
      cat: [],
      chartOptions: {
        colors: ['#EC008C'],
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '35%',
            startingShape: 'rounded',
            endingShape: 'rounded',
          },
        },
        xaxis: {
          categories: [],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter: value => kFormatter(value, 0),
          },
        },
        grid: {
          strokeDashArray: 10,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        stroke: {
          curve: 'smooth',
          width: 6,
          lineCap: 'round',
          colors: ['#fff'],
        },
        responsive: [
          {
            breakpoint: 1400,
            options: {
              plotOptions: {
                bar: {
                  borderRadius: 10,
                  columnWidth: '50%',
                },
              },
            },
          },
        ],
      },
      chartData: [
        {
          data: [
            {
              x: '',
              y: 0,
            },
          ],
        },
      ],
      customChartColor: '#3b3559',
      libraries: [
        {
          icon: iconBook,
          title: 'E-Book',
          total: 0,
          color: '#EC008C',
          keyName: 'count_ebook',
          keyRatioName: 'ratio_ebook',
        },
        {
          icon: iconMusic,
          title: 'Audio Book',
          total: 0,
          color: '#7D2B8B',
          keyName: 'count_audio',
          keyRatioName: 'ratio_audio',
        },
        {
          icon: iconPlay,
          title: 'Video Book',
          total: 0,
          color: '#149FE5',
          keyName: 'count_video',
          keyRatioName: 'ratio_video',
        },
        {
          icon: iconNote,
          title: 'Paket Buku',
          total: 0,
          color: '#56CA00',
          keyName: 'count_packet',
          keyRatioName: 'ratio_packet',
        },
      ],
      elearnings: [
        {
          icon: iconMessages,
          title: 'Pelajaran',
          total: 0,
          color: '#EC008C',
          keyName: 'count_lesson',
          keyRatioName: 'ratio_lesson',
        },
        {
          icon: iconDocumentText,
          title: 'Latihan',
          total: 0,
          color: '#FFB400',
          keyName: 'count_exercise',
          keyRatioName: 'ratio_exercise',
        },
        {
          icon: iconSimcard,
          title: 'Ujian',
          total: 0,
          color: '#56CA00',
          keyName: 'count_exam',
          keyRatioName: 'ratio_exam',
        },
      ],
      schools: [],
      activeSchools: [],
      activeStudents: [],
      activeTeachers: [],
      service: 'dashboardsuperadmin',
      date: [
        {
          id: `01-${new Date().getFullYear()}`,
          name: `January ${new Date().getFullYear()}`,
        },
        {
          id: `02-${new Date().getFullYear()}`,
          name: `February ${new Date().getFullYear()}`,
        },
        {
          id: `03-${new Date().getFullYear()}`,
          name: `March ${new Date().getFullYear()}`,
        },
        {
          id: `04-${new Date().getFullYear()}`,
          name: `April ${new Date().getFullYear()}`,
        },
        {
          id: `05-${new Date().getFullYear()}`,
          name: `May ${new Date().getFullYear()}`,
        },
        {
          id: `06-${new Date().getFullYear()}`,
          name: `June ${new Date().getFullYear()}`,
        },
        {
          id: `07-${new Date().getFullYear()}`,
          name: `July ${new Date().getFullYear()}`,
        },
        {
          id: `08-${new Date().getFullYear()}`,
          name: `August ${new Date().getFullYear()}`,
        },
        {
          id: `09-${new Date().getFullYear()}`,
          name: `September ${new Date().getFullYear()}`,
        },
        {
          id: `10-${new Date().getFullYear()}`,
          name: `October ${new Date().getFullYear()}`,
        },
        {
          id: `11-${new Date().getFullYear()}`,
          name: `November ${new Date().getFullYear()}`,
        },
        {
          id: `12-${new Date().getFullYear()}`,
          name: `December ${new Date().getFullYear()}`,
        },
      ],
      paramsDate: '',
      dateNow: '',
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      total: 0,
      userActivities: [],
    }
  },
  mounted() {
    this.dateNow = `${this.monthNames[new Date().getMonth()]} ${new Date().getFullYear()}`
    this.checkFirstParams()
    this.getDataCount({ date: this.paramsDate })
    this.listSchool()
    console.log(this.activeTeachers)
  },
  methods: {
    async getDataCount(params) {
      await this.$services.ApiServices.list(this.service, { ...params }).then(({ data }) => {
        this.dashboard = data.data
        this.favoriteBooks = data.data.favorite_books
        this.bookPurchases = data.data.school_buys
        this.activeSchools = data.data.active_schools
        this.activeStudents = data.data.active_students
        this.activeTeachers = data.data.active_teachers
        this.userActivities = data.data.user_activities
        data.data.user_activities.forEach(el => this.yearActivities.push(el.year))
        this.cat = []
        const chartData = [
          {
            data: [],
          },
        ]
        chartData[0].data = data.data.user_activities.map(el => ({ x: el.month + ' ' + el.year, y: el.total_activity }))
        this.chartData = chartData
        // this.cat = data.data.user_activities.map(el => el.month)
        // chartData[0].data = data.data.user_activities.map(el => el.total_activity)
        // this.chartOptions.xaxis.categories = this.cat
        // this.chartData = chartData
        this.$refs.chart.refresh()

        const map = am4core.create('chartdiv', am4maps.MapChart)

        map.geodata = mapView

        map.projection = new am4maps.projections.Mercator()

        // Configure series
        const polygonSeries = map.series.push(new am4maps.MapPolygonSeries())
        polygonSeries.heatRules.push({
          property: 'fill',
          target: polygonSeries.mapPolygons.template,
          min: am4core.color('#FFBBE3'),
          max: am4core.color('#EC008C'),
          logarithmic: true,
        })
        polygonSeries.useGeodata = true
        polygonSeries.mapPolygons.template.tooltipText = '{name} {value}'
        polygonSeries.data = data.data.total_users_province
        // Configure Template
        const polygonTemplate = polygonSeries.mapPolygons.template
        polygonTemplate.fill = am4core.color('#FFECF7')
        polygonTemplate.propertyFields.fill = 'fill'

        // Add zoom control
        map.zoomControl = new am4maps.ZoomControl()
        // Add and configure small map
        map.smallMap = new am4maps.SmallMap()
        map.smallMap.series.push(polygonSeries)

        polygonSeries.mapPolygons.template.events.on('hit', ev => {
          map.zoomToMapObject(ev.target)
        })

        Object.keys(data.data).forEach((key, item) => {
          this.menus.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
        Object.keys(data.data).forEach((key, item) => {
          this.libraries.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
        Object.keys(data.data).forEach((key, item) => {
          this.topicAndForum.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
        Object.keys(data.data).forEach((key, item) => {
          this.elearnings.forEach(el => {
            if (el.keyName === key) {
              el.total = data.data[key]
            }
            if (el.keyRatioName === key) {
              el.ratio = data.data[key]
            }
          })
        })
      })
      this.isLoadingCard = false
    },
    checkFirstParams() {
      this.date.forEach(item => {
        if (item.name === this.dateNow) this.paramsDate = item.id
      })
    },
    async listSchool(params = {}) {
      await this.$services.ApiServices.list('school', {
        ...params,
        per_page: 5,
        sort: 'DESC',
      }).then(
        ({ data }) => {
          this.schools = data.data
        },
        err => console.error(err),
      )
    },
    dateFormat(time) {
      return dateTimeFormat(time, '/')
    },
    randomBgColor() {
      const color = ['warning', 'primary', 'success', 'error']

      const random = Math.floor(Math.random() * color.length)

      return color[random]
    },
    randomTextColor() {
      const colorText = ['warning--text', 'primary--text', 'success--text', 'error--text']

      const random = Math.floor(Math.random() * colorText.length)

      return colorText[random]
    },
    handleSelectChange(event) {
      this.$services.ApiServices.list(this.service, { year: event }).then(({ data }) => {
        this.cat = []
        const chartData = [
          {
            data: [],
          },
        ]
        chartData[0].data = data.data.user_activities.map(el => ({ x: el.month, y: el.total_activity }))
        this.chartData = chartData
        // this.cat = data.data.user_activities.map(el => el.month)
        // chartData[0].data = data.data.user_activities.map(el => el.total_activity)
        // this.chartOptions.xaxis.categories = this.cat
        // this.chartData = chartData
        this.$refs.chart.refresh()
      })
    },
  },
  // beforeDestroy() {
  //   if (this.chart) {
  //     this.chart.dispose()
  //   }
  // },
}
</script>

<style lang="scss" scoped>
.comboboxHidden::v-deep > .v-input__control > .v-text-field__details {
  display: none !important;
}
.card-info,
.card-img {
  z-index: 1;
}
.card-info .font-weight,
.card-info .font-light {
  color: #fff;
}
.chartdiv {
  width: 100%;
  height: 350px;
}
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  right: 0;
}
.gamification-john-pose-2 {
  bottom: 0;
  right: 15%;
}
.gamification-tree-4 {
  bottom: 0;
  right: 30%;
}
</style>
